import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ContentfulRichTech from "../components/ContentfulRichText";

import { HomeIcon } from "@heroicons/react/solid";
import Newsletter from "../components/Newsletter";
import SEO from "../components/SEO";

const pages = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];

const Team = ({ data }) => {
  const people = data.allContentfulPerson.nodes;
  return (
    <Layout>
      <SEO title="Our Team" slug="/team" />
      {/* HERO */}
      <div className="hidden lg:block relative bg-e-blue overflow-hidden h-[350px]">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.ctfassets.net/agskyiykp2ye/7eBpUkhTiU7Zi7VG34EdgW/2b7694f78b5f6f22171f30a2197e602b/AdobeStock_389431112.jpeg"
            alt=""
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="366"
            viewBox="0 0 925 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0"
          >
            <path
              d="M344.5 0.327271L0 365.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-[310px] px-4 flex justify-end items-end">
          {/* <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Our Team
          </h1> */}
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/"
                    className="text-white hover:text-e-blue transition"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="/team"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    Team
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bg-white mt-12 relative">
        <svg
          className="hidden lg:block absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-bold text-gray-500 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Meet our team
            </p>
            <p className="max-w-5xl mt-5 mx-auto text-xl text-gray-500">
              We work across all areas of Employment & Safety. We develop
              particular skills and experience from those opportunities. We not
              only consider what specific skills and experience you need but
              that we are also the right fit for your particular team.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 pb-12">
          <div className="space-y-12">
            <ul role="list" className="flex flex-col gap-8">
              {people.map((person) => (
                <li key={person.id}>
                  <Link
                    to={`/${person.name
                      .replace(
                        /[A-Z]/g,
                        (match, offset) =>
                          (offset > 0 ? "-" : "") + match.toLowerCase()
                      )
                      .replace(/[:.'() ]/g, "-")
                      .replaceAll("--", "-")
                      .replaceAll("--", "-")}`}
                    className="py-8 sm:grid sm:grid-cols-3 sm:gap-4 lg:gap-4 "
                  >
                    <div className=" aspect-square lg:h-[300px] relative">
                      <GatsbyImage
                        className="object-cover shadow-lg "
                        image={person.image.gatsbyImageData}
                        alt={person.name}
                      />
                      <svg
                        className="absolute z-10 top-0 right-0"
                        width="112"
                        height="300"
                        viewBox="0 0 112 300"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50.2396 0L0 300H112V0H50.2396Z"
                          fill="url(#paint0_linear_1761_4216)"
                          fillOpacity="0.3"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1761_4216"
                            x1="56"
                            y1="0"
                            x2="56"
                            y2="243"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#1D71B8" />
                            <stop
                              offset="1"
                              stopColor="#1D71B8"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="sm:col-span-2 h-[300px]">
                      <div className="relative">
                        <div className="flex flex-row pt-6">
                          <h3 className="text-xl text-e-dark-blue font-semibold">
                            {person.name}
                          </h3>
                          <p className=" ml-2 font-bold text-e-blue h-6 border-l-2 w-2 border-e-blue"></p>
                          <p className=" text-xl text-e-dark-blue font-semibold uppercase">
                            {person.role}
                          </p>
                          <span className="inline-flex ml-3 justify-center sm:justify-start z-10">
                            {/* <a className="ml-3 text-e-blue hover:text-gray-600 cursor-pointer transition-all">
                              <svg
                                fill="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-5 h-5"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                              </svg>
                            </a> */}
                            <a
                              className="ml-3 text-e-blue hover:text-gray-600 cursor-pointer transition-all"
                              href="https://au.linkedin.com/company/edge-legal-aus"
                              target="blank"
                            >
                              <svg
                                fill="currentColor"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="0"
                                className="w-5 h-5"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="none"
                                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                                ></path>
                                <circle
                                  cx="4"
                                  cy="4"
                                  r="2"
                                  stroke="none"
                                ></circle>
                              </svg>
                            </a>
                          </span>
                        </div>
                        <div className="text-lg h-24  mt-10 mb-16 overflow-hidden">
                          <ContentfulRichTech richText={person.bio} />
                          <div className="absolute bottom-24 w-full z-10 h-20 bg-gradient-to-t from-white"></div>
                        </div>

                        <svg
                          width="250"
                          height="44"
                          viewBox="0 0 250 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute"
                        >
                          <path
                            d="M1 1H250"
                            stroke="#1D71B8"
                            strokeLinecap="round"
                          />
                          <path
                            d="M134.902 43L163.728 0.999992"
                            stroke="#1D71B8"
                            strokeLinecap="round"
                          />
                        </svg>
                        <button className="text-e-blue inline-flex items-center mt-3 hover:underline cursor-pointer relative z-10">
                          Read More
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Newsletter />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulPerson(sort: { fields: position, order: ASC }) {
      nodes {
        id
        name
        role
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        bio {
          raw
        }
        phone
        email
        position
      }
    }
  }
`;
export default Team;
